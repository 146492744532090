import {
  FETCH_VIDEO_URLS_SUCCESS,
  SET_SEEK_TO
} from '../actions/video';

const initialState = {
  smartPathUrls: [],
  seekTo: ''
};

const video = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEEK_TO: {
      return {
        ...state,
        seekTo: action.payload
      };
    }
    case FETCH_VIDEO_URLS_SUCCESS: {
      return {
        ...state,
        smartPathUrls: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export default video;