import { SET_DEBUG } from '../actions/debug';

const initialState = {
  debug: ""
};

 const debug = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEBUG: {
      return {
        ...state,
        debug: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export default debug;
