import * as errorActions from '../actions/errors';

const errorObj = {
  [errorActions.FETCH_VIDEO_URLS_FAILURE]: 'There was an error fetching the video URLs for this presentation. Please try again later.',
  [errorActions.POST_COMPONENT_EVENT_FAILURE]: 'There was an error submitting your request. Please try again later.',
  [errorActions.POLL_COMPONENT_EVENTS_FAILURE]: 'There was an error fetching the latest presentation data. Please try and refresh the page.',
  [errorActions.GET_CATCH_UP_EVENTS_FAILURE]: 'There was an error fetching the latest presentation data. Please try again later.',
  [errorActions.FETCH_EVENT_FAILURE]: 'There was an error fetching information about this event. Please try again later.',
  [errorActions.FETCH_EVENT_THEME_FAILURE]: 'There was an error fetching the theme for this event. Please try again later.',
  [errorActions.USER_SIGN_IN_FAILURE]: 'There was an error logging you in. Please try again later.',
  [errorActions.USER_REGISTER_FAILURE]: 'There was an error registering for the event. Please try again later.',
  [errorActions.FETCH_USER_FAILURE]: 'There was an error logging you in. Please try again later.',
};

export default errorObj;