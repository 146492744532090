import {
  POST_COMPONENT_EVENT_SUCCESS,
  GET_CATCH_UP_EVENTS_SUCCESS
} from '../actions/componentEvents';
import { POLL_COMPONENT_EVENTS_SUCCESS } from '../actions/presentation';

const initialState = {
  screens: {},
  screensPerType: {
    lobby: [],
    event: [],
    exit: [],
    teamView: []
  },
  activeAudienceScreenId: '', // the screen that the audience should be viewing
  components: {},
  qaQuestions: {},
  qaFolders: {},
  pollList: {},
  pollActiveId: '',
  surveyActiveId: '',
  surveyAudiencePageId: '',
  surveyList: {},
  surveySubmissions: {},
  slideshow: {
    pdfURL: '',
    fileName: '',
    currentIndex: 0,
    isUploading: false,
    ratio: {},
    allowDownload: false
  }
};

const controlRoom = (state = initialState, action) => {
  switch (action.type) {
    case POST_COMPONENT_EVENT_SUCCESS:
    case POLL_COMPONENT_EVENTS_SUCCESS:
    case GET_CATCH_UP_EVENTS_SUCCESS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

export default controlRoom;
 