import { device } from '../configs/deviceBreakpoints';

export const getEmptyTheme = () => ({
  accountId: '',
  id: '',
  name: 'Untitled Theme',
  description: '',
  public: false,
  default: false,
  colors: {
    titleBarBackground: '#000000',
    titleBarText: '#FFFFFF',
    componentBackground: '#FFFFFF',
    primaryText: '#000000',
    secondaryText: '#000000',
    buttonsAndForms: '#00796b',
    buttonText: '#FFFFFF',
    error: '#f44336',
  },
  screens: {
    lobby: getEmptyLayoutTheme(),
    event: getEmptyLayoutTheme(),
    exit: getEmptyLayoutTheme()
  },
  createdAt: '',
  updatedAt: '',
  deviceBreakpoints: {...device}
});

export const getEmptyLayoutTheme = () => ({
  background: {
    backgroundColor: '#f5f5f5',
    backgroundImage: {
      id: null,
      name: null,
      url: null
    }
  },
  header: {
    backgroundColor: '#000',
    color: '#FFF',
    backgroundImage: {
      id: null,
      name: null,
      url: null
    },
    logo: {
      id: null,
      name: null,
      url: null,
      alignment: 'center'
    }
  },
  showHeader: false
});
