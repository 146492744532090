import { DebugManager } from 'wcng-ui-common';
import { SET_DEBUG } from '../actions/debug'

const { dmInstance } = DebugManager;

 const debugManager = (store) => {
  return next => async (action) => {
    //If we are setting the debug flag, then pass it into DebugManager
    if(action.type === SET_DEBUG)
    {
      dmInstance.debug = action.payload;
    }
    next(action);
  };
}

export default debugManager;
