import { createSelector } from 'reselect';
import { 
  EventConstants,
  hmsToSeconds,
  timeDifferenceBetweenDates
} from 'wcng-ui-common';
import {
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_THEME_SUCCESS,
  CLEAR_THEME,
  POST_SENTIMENT_SUCCESS,
  POST_SENTIMENT_FAILURE
} from '../actions/events';

import { getEmptyTheme } from '../configs/themeDefaults';

const { EventType: { AUTOCAST } } = EventConstants;

const initialState = {
  event: {},
  theme: getEmptyTheme()
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_SUCCESS: {
      return {
        ...state,
        event: action.payload
      };
    }
    case FETCH_EVENT_THEME_SUCCESS: {
      return {
        ...state,
        theme: { ...state.theme, ...action.payload}
      };
    }
    case POST_SENTIMENT_SUCCESS: {
      return {
        ...state,
        postSentimentSuccess: true
      };
    }
    case POST_SENTIMENT_FAILURE: {
      return {
        ...state
      };
    }
    case CLEAR_THEME: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
}

const getCurrentEvent = (state) => state.event.event;
export const customPlayerTime = createSelector([getCurrentEvent], (event = {}) => {
  const { 
    eventType = '',
    recordingStartTime = '',
    recordingEndTime = '',
    startTime = '',
    endTime = ''
  } = event;

  if (recordingStartTime || recordingEndTime) {
    return {
      startTime: hmsToSeconds(recordingStartTime),
      endTime: hmsToSeconds(recordingEndTime)
    }
  }

  if (eventType.toLowerCase() === AUTOCAST) {
    const duration = Math.round(timeDifferenceBetweenDates(new Date(startTime), new Date(endTime)));
    return {
      startTime: 0,
      endTime: duration
    }
  }

  return null;
});

export default events;
