import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import {
  configureStore,
  // createImmutableStateInvariantMiddleware,
  // createSerializableStateInvariantMiddleware
} from '@reduxjs/toolkit'
import 'typeface-roboto';
import ReduxThunk from 'redux-thunk';
import { LoggerUtil, RUMUtil, ErrorHandlerUtils, HTTPUtils } from 'wcng-ui-common';

import './css/animations.css';

import eventPumpListener from './middleware/eventPumpListener';
import userStorageManager from './middleware/userStorageManager';
import debugManager from './middleware/debugManager';

import rootReducer from './reducers';
import config from './configs/config';
import { initI18n } from './language';
import App from './App';

// TODO 
// Incorporate getDefaultMiddleware from redux toolkit
// First attempt to do so did not work out of the box and
// seems to require a refactor in our thunk functions so we have
// to go back to using (previous) redux-thunk..
// Add strict mode once material-ui upgrade is complete
// Include invariant middleware below -> produces many bugs
// Reintroduce strict mode with material-ui upgrade

const middleware = [
  ReduxThunk,
  eventPumpListener,
  userStorageManager,
  ErrorHandlerUtils,
  LoggerUtil,
  debugManager
]

const store = configureStore({
  reducer: rootReducer,
  middleware
})


HTTPUtils.responseHandler(axios, {})(store);
HTTPUtils.initAxios(axios);

// initialize Datadog RUM here - WN-3746
// see https://docs.datadoghq.com/real_user_monitoring/browser/
const { useDatadogRUM, datadogEnv, deployBranch } = config;
if (useDatadogRUM) {
  const opts = {
    env: datadogEnv,
    version: deployBranch
  };
  RUMUtil.init('audience', opts);
}

// initialize i18n
initI18n();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App store={store}/>
    </BrowserRouter>
  </React.StrictMode>
);
