import { EventPumpAudience } from 'wcng-ui-common';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

import config from '../configs/config';

import {
  GET_CATCH_UP_EVENTS,
  START_POLLING_COMPONENT_EVENTS,
  STOP_POLLING_COMPONENT_EVENTS,
  POLL_COMPONENT_EVENTS_SUCCESS
} from '../actions/presentation';

import { GET_CATCH_UP_EVENTS_FAILURE } from '../actions/errors';
import { INITIATE_VIDEO_PLAYER, DESTROY_VIDEO_PLAYER } from '../actions/video';

const { instance } = EventPumpAudience;
let cachedEventModel = null;

const eventPumpListener = (store) => {
  instance.setStore(store);
  instance.on('componentUpdate', data => handleComponentUpdate(store.dispatch, data));

  return next => async (action) => {
    switch (action.type) {
      case GET_CATCH_UP_EVENTS: {
        const { eventId, accountId, previewToken, startTime } = action.payload;
        const { eventsHost } = config.api;

        try {
          const eventList = await instance.getCatchUpEvents(
            eventId,
            accountId,
            eventsHost,
            previewToken,
            startTime
          );
          instance.dataHandler(eventList);
        } catch (e) {
          store.dispatch({
            type: GET_CATCH_UP_EVENTS_FAILURE
          });
        }
        break;
      }
      case START_POLLING_COMPONENT_EVENTS: {
        const { eventId, accountId, previewToken } = action.payload;
        const { eventsHost } = config.api;
        if (isUndefined(eventId)) {
          return false;
        }
        instance.startHttpPolling(accountId, eventId, eventsHost, previewToken);
        break;
      }
      case STOP_POLLING_COMPONENT_EVENTS: {
        instance.stop();
        break;
      }
      case INITIATE_VIDEO_PLAYER: {
        const { 
          videoId, 
          videoOptions, 
          smartPathUrls, 
          userId, 
          eventId, 
          vbiAccountId, 
          type, 
          hasPreviewToken, 
          authParams, 
          presentationType
        } = action.payload;
        instance.initiateVideoPlayer(
          eventId, 
          vbiAccountId, 
          userId, 
          videoId, 
          videoOptions, 
          smartPathUrls, 
          config.vbi, 
          type, 
          hasPreviewToken, 
          authParams,
          presentationType
        );
        break;
      }
      case DESTROY_VIDEO_PLAYER: {
        const { elemId } = action.payload;
        instance.destroyVideoPlayer(elemId);
        break;
      }
      default: {
        next(action);
      }
    }

    return true;
  };
}

function handleComponentUpdate(dispatch, data) {
  const dataStr = JSON.stringify(data);

  if (dataStr === cachedEventModel) {
    return;
  }

  cachedEventModel = dataStr;

  if (isNull(cachedEventModel)) {
    cachedEventModel = JSON.stringify(data);
  }

  dispatch({
    type: POLL_COMPONENT_EVENTS_SUCCESS,
    payload: data
  });
}

export default eventPumpListener;
