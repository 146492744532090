import qs from 'qs';
import axios from 'axios';
import { RUMUtil } from 'wcng-ui-common';
import isEqual from 'lodash/isEqual';
import Config from '../configs/config';
import {
  FETCH_EVENT_FAILURE,
  FETCH_EVENT_THEME_FAILURE
} from './errors';

export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_THEME_SUCCESS = 'FETCH_EVENT_THEME_SUCCESS';
export const CLEAR_THEME = 'CLEAR_THEME';
export const POST_SENTIMENT_SUCCESS = 'POST_SENTIMENT_SUCCESS';
export const POST_SENTIMENT_FAILURE = 'POST_SENTIMENT_FAILURE';

const { host, esdHost } = Config.api;

export const fetchEvent = eventId => async (dispatch, getState) => {
  const { event } = getState().event;
  try {
    const res = await axios.get(`${host}/public/event/${eventId}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    });

    const {
      data: {
        data
      } = {}
    } = res;

    if (!isEqual(event, data)) {
        dispatch({ type: FETCH_EVENT_SUCCESS, payload: data });
    }

    const { useDatadogRUM } = Config;
    if (useDatadogRUM) {
      const eventObj = res && res.data && { ...res.data.data };
      delete eventObj.author;
      RUMUtil.setContext(eventObj);
    }

    return res;
  } catch (err) {
    dispatch({ type: FETCH_EVENT_FAILURE });
    console.error(err.message);
    throw err;
  }
};

export const clearTheme = () => ({
  type: CLEAR_THEME
})

export const fetchEventTheme = (accountId, eventId, token = false) => async (dispatch) => {
  let tokenStr = '';

  if (token) {
    const params = qs.stringify({ token });
    tokenStr = `?${params}`;
  }

  try {
    const res = await axios.get(`${host}/account/${accountId}/event/${eventId}/theme${tokenStr}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    });

    dispatch({ type: FETCH_EVENT_THEME_SUCCESS, payload: res.data.data });
    return;
  } catch (err) {
    console.error(err.message);
    dispatch({ type: FETCH_EVENT_THEME_FAILURE });
    throw (err);
  }
};

/**
 * Action for ESD sentiment tracking widget
 */
export const postEsdSentiment = (sentimentType, userId, eventId) => async (dispatch) => {
  const data = {
    sentimentType,
    userId,
    eventId
  }
    const localAxios = axios.create({
        timeout: 5000,
        responseType: 'json'
    });
  
    localAxios.interceptors.response.use((res) => {
        dispatch({ type: POST_SENTIMENT_SUCCESS, payload: res.data.data });
    }, (error) => {
        dispatch({ type: POST_SENTIMENT_FAILURE });
    });

    await localAxios.post(esdHost, data, {
        withCredentials: false
    });
    return;
};