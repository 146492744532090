/**
 * Retrieve session storage object by key.
 * 
 * @param {string} key - Key to assign to the session storage value.
 * @returns {Object}  
 */
export const getSessionCache = (key) => {
    try {
        const serializedCache = sessionStorage.getItem(key);
        if (serializedCache === null) {
            return undefined;
        }
        return JSON.parse(serializedCache);
    } catch (err) {
        console.log(`Unable to retrieve sessionStorage: ${key}`);
        return undefined;
    }
}

/**
 * Create (or update) entry in session storage using the 
 * key value pair.
 * 
 * @param {string} key - Key to assign to the session storage value.
 * @param {Object} value - Data object to store in session storage.
 */
export const setSessionCache = (key, value) => {
    try {
        const serializedCache = JSON.stringify({value, expiry: Date.now()});
        sessionStorage.setItem(key, serializedCache);
    } catch (err) {
        console.log(`Unable to set sessionStorage: ${err}. key: ${key} value: `);
        console.log(value);
    }
}

/**
 * Delete item from session storage using key.
 * 
 * @param {string} key - Key used to remove item from session storage.
 */
export const purgeSessionCache = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (err) {
        console.log(`Unable to remove SessionStorage: ${key}`);
    }
}