import { UNAUTHENTICATED } from '../actions/user';
import { purgeSessionCache } from '../sessionStorage';

/**
 * Valid storage types used as keys for 
 * session storage.
 */
export const StorageEnum = Object.freeze({
  USER_ME: 'user-session-me'
});

/**
 * Middleware to invalidate user cache stored
 * in session storage when a user is no longer
 * authenticated.
 */
const userStorageManager = (store) => {

  return next => (action) => {
    let key;
    
    switch (action.type) {
      case UNAUTHENTICATED: {
        const { event } = store.getState().event;

        key = `${StorageEnum.USER_ME}-${event.id}`;
        purgeSessionCache(key);

        next(action);
        break;
      }
      default:
        next(action);
    }
  }
}

export default userStorageManager;