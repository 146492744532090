import { Provider } from 'react-redux';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import './App.css';
import Main from './components/Main';
import ErrorSnackbar from './components/common/ErrorSnackbar';
import ErrorModal from './components/common/ErrorModal';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body2'
      },
    }
  }
});

function App(props) {
  return (
    <Provider store={props.store}>
      <ThemeProvider theme={theme}>
        <ErrorSnackbar />
        <ErrorModal />
        <Main />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
