import { getSessionCache, setSessionCache } from '../sessionStorage';

export const cacheExpiration = 300000;

/**
 * Valid cache types used as keys for 
 * session storage.
 */
export const CacheEnum = Object.freeze({
    IP_ADDRESS: 'ip-address', //
    VIDEO_URLS: 'video-urls',
    MICROSOFT_ECDN_TENANT_ID: 'microsoft-tenant-id',
    HIVE_URL: 'hive-url',
    VIDEO_STREAM_AUTH: 'video-stream-auth',
    SET_COOKIES: 'set-cookies'
});

/**
 * Retrieves stored cache in session storage by type.
 * If the session storage entry is found:
 *  - Check that is not expired
 *  - Dispatch the successHandler 
 * 
 * @param {Function} func - Wrapped function (fetch) 
 * @param {string} key - Type of cache requested. Used as key in session storage.
 * @param {Function} dispatch - Injected dispatch function from redux.
 * @param {Function} successHandler - Callback used after successful cache retrieval.
 * @param {Object} args - Parameters passed to the wrapped function.
 * 
 */
const withCacheManager = async (func, key, dispatch, successHandler, ...args) => {

    let res;
    const cache = getSessionCache(key);

    //Check if cache was found and verify that it is not expired.
    if (cache && (parseInt(Date.now()) - parseInt(cache.expiry) <= cacheExpiration)) {
        res = cache.value;
        //Dispatch the successHandler (actionCreator) passing retrieved cache.
        if (successHandler) {
            dispatch(successHandler(res));
        }
    }
    else {
        //Cache was not found or it was expired.  
        //Pass control back to wrapped function. 
        res = await func(...args)(dispatch);
        if (res) {
            //Store return value along with key
            //in session storage.
            setSessionCache(key, res);
        }
    }
    return res;
}

export default withCacheManager;
