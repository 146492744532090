import {
  USER_SIGN_IN_SUCCESS,
  USER_REGISTER_SUCCESS,
  JWT_TOKEN_ERROR,
  FETCH_USER_SUCCESS,
  UNAUTHENTICATED,
  UPDATE_IS_MOBILE,
  CLIENT_TOKEN_SUCCESS,
  CLEAR_CLIENT_TOKEN,
  UNAUTHORIZED
} from '../actions/user';
import {
  FETCH_USER_FAILURE,
} from '../actions/errors';
import {
  PREVIEW_TOKEN_SUCCESS
} from '../actions/preview';

const initialState = {
  account: {},
  user: null,
  clientToken: false,
  isMobile: false,
  unauthorized: false
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_IN_SUCCESS:
    case USER_REGISTER_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    }
    case JWT_TOKEN_ERROR: {
      return {
        ...state,
        ...action.payload
      };
    }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user
        },
        account: {
          ...state.account,
          ...action.payload.account
        }
      };
    case FETCH_USER_FAILURE: {
      return {
        ...state,
        user: {}
      }
    }
    case UNAUTHORIZED: {
      return {
        state,
        unauthorized: true
      }
    }
    case UNAUTHENTICATED: 
    case CLEAR_CLIENT_TOKEN: {
      return { ...initialState };
    }
    case PREVIEW_TOKEN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        account: action.payload.account
      };
    }
    case CLIENT_TOKEN_SUCCESS: {
      return {
        ...state,
        clientToken: action.payload.clientToken,
        user: action.payload.user,
        account: action.payload.account
      };
    }
    case UPDATE_IS_MOBILE: {
      return {
        ...state,
        isMobile: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export default users;
