/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import Config from '../configs/config';

export const COMPONENT_EVENT_EVENT_CONFIG = 'COMPONENT_EVENT_EVENT_CONFIG';

export const getComponentEventEventConfig = (accountId, eventId) => async (dispatch) => {
  const { eventsHost } = Config.api;
  try {
    const res = await axios.get(`${eventsHost}/account/${accountId}/event/${eventId}/eventConfig`);

    const {
      data: {
        data: {
          qaEmailForwardingConfig = {},
          hideInArchiveConfig = {}
        } = {}
      } = {}
    } = res;

    dispatch({
      type: COMPONENT_EVENT_EVENT_CONFIG,
      payload: {
        ...qaEmailForwardingConfig,
        hideInArchiveConfig
      }
    });
  } catch (e) {
    console.error(e);
  }
};
