import React from 'react';
import { Route } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const GenericRoute = ({ component: Component, ...rest }) => {
  const {
    location: {
      key
    } = {},
    fade = true,
  } = rest;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (fade) {
          return (
            <FadeIn key={key}>
              <Component {...props} />
            </FadeIn>
          )
        } else {
          return (<Component {...props} />)
        }
      }}
    />
  )};

const fadeInSettings = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const FadeIn = styled.div`
  display: flex;
  flex: 1;
  animation: ${fadeInSettings} .4s;
`;

export default GenericRoute;