import axios from 'axios';
import qs from 'qs';
import { RUMUtil } from 'wcng-ui-common';
import i18n from 'i18next';

import Config from '../configs/config';
import {
  USER_REGISTER_FAILURE,
  USER_SIGN_IN_FAILURE,
  FETCH_USER_FAILURE,
} from './errors';
import { breakpoint } from '../configs/deviceBreakpoints';

const { userHost } = Config.api;

export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const UPDATE_IS_MOBILE = 'UPDATE_IS_MOBILE';
export const CLIENT_TOKEN_SUCCESS = 'CLIENT_TOKEN_SUCCESS';
export const JWT_TOKEN_ERROR = 'JWT_TOKEN_ERROR';
export const CLEAR_CLIENT_TOKEN = 'CLEAR_CLIENT_TOKEN';
export const UNAUTHORIZED = 'UNAUTHORIZED';

const headers = { 'Content-Type': 'application/json' };

/**
   * This condition may eventually check for user device type rather than
   * assume that any screen width under 768 is mobile. Mobile is considered
   * tablets and phones.
   *
   * But, for now this should suffice.
   */
const getIsMobile = screenWidth => screenWidth <= breakpoint.tablet;

export const checkClientToken = (eventId, audienceToken) => async dispatch => {
  let res;
  const params = qs.stringify({ audienceToken });
  try {
    res = await axios.get(`${userHost}/user/event/${eventId}/me?${params}`);
  } catch (e) {
    dispatch({
      type: CLEAR_CLIENT_TOKEN
    });
    console.error(e);
    throw(e);
  }

  // for some reason, fetchUser is not invoked for client token events
  const { useDatadogRUM } = Config;
  if (useDatadogRUM) {
    RUMUtil.setUser(res && res.data && res.data.data && res.data.data.user);
  }
  
  dispatch({
    type: CLIENT_TOKEN_SUCCESS,
    payload: {
      clientToken: audienceToken,
      user: res.data.data.user,
      account: res.data.data.account
    }
  });
  return res.data.data.user;
};

export const checkJwt = (eventId, token) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${userHost}/public/event/${eventId}/jwt?token=${token}`);
    dispatch({ 
      type: FETCH_USER_SUCCESS, 
      payload: data 
    });
  } catch (e) {
    const {
      response: {
        data: {
          validationErrors = []
        } = {}
      } = {}
    } = e;
    dispatch({ 
      type: JWT_TOKEN_ERROR,
      payload: {
        jwt: token,
        jwtErrors: validationErrors
      }
    });
    throw (e);
  }
};

export const registerUser = (eventId, payload, liveAudience = false) => async (dispatch) => {
  try {
    const res = await axios(`${userHost}/public/user/event/${eventId}/registration?inPerson=${liveAudience}`, {
      method: 'POST',
      headers,
      withCredentials: true,
      data: payload
    });

    dispatch({ type: USER_REGISTER_SUCCESS, payload: res.data.data });
    return res;
  } catch (err) {
    let message;

    if (err.response && err.response.status === 409) {
      message = i18n.t('REGISTRATION_MSG_ALREADY_REGISTERED_ERROR');
    }

    dispatch({ type: USER_REGISTER_FAILURE, payload: message });
    return err;
  }
};

export const registerUserEmailVerify = (eventId, payload) => async (dispatch) => {
  try {
    const res = await axios(`${userHost}/public/user/event/${eventId}/registration/email`, {
      method: 'POST',
      headers,
      withCredentials: true,
      data: payload
    });

    dispatch({ type: USER_REGISTER_SUCCESS, payload: res.data.data });
    return res;
  } catch (err) {

    if (err.response && err.response.status !== 400) {
      dispatch({ type: USER_REGISTER_FAILURE });
    }
    
    return err;
  }
};

/**
 * "Registers" an SSO user when they initially view an SSO type of event.
 * This gives us a record of who viewed an event in order to tie into VBI data.
 * This is not critical, so if there is an exception, contain it here.
 * @param {*} eventId 
 * @param {*} user 
 */
export const recordSsoUserForEvent = (eventId, accountId, liveAudience = false) => async (dispatch) => {
  try {
    await axios(`${userHost}/user/account/${accountId}/event/${eventId}/registration/sso?inPerson=${liveAudience}`, {
      method: 'POST'
    });
  } catch (err) {
    console.error('Problem recording sso user data for event ', err);
  }
}

/**
 * TODO This might no longer be used (since only LoginDialog uses it and that appears to be an orphan.)
 * Look to remove this
 * @param {} eventId 
 * @param {*} payload 
 */
export const findUser = (eventId, payload) => async (dispatch) => {
  try {
    const res = await axios(`${userHost}/public/user/event/${eventId}/signin`, {
      method: 'POST',
      headers,
      withCredentials: true,
      data: payload
    });

    dispatch({ type: USER_SIGN_IN_SUCCESS, payload: res.data.data });
    return res;
  } catch (err) {
    let message;

    if (err.response && err.response.status === 404) {
      message = 'That email address was not found in our registry. Click "New Registration" to register and watch event.';
    }

    dispatch({ type: USER_SIGN_IN_FAILURE, payload: message });
    return err;
  }
};

export const fetchUserForEvent = eventId => async (dispatch) => {
  let resData;
    try {
      const res = await axios.get(`${userHost}/user/event/${eventId}/me`, {
        headers,
        withCredentials: true
      })
      resData = res.data.data;
    }
    catch (err) {
      console.warn('authentication failed fetching user details', err);
      const {
        response: {
          status
        }
      } = err;
      if (status === 403) {
        dispatch({ type: UNAUTHORIZED });
      } else {
        dispatch({ type: FETCH_USER_FAILURE });
      }
      throw(err);
    }

  const { useDatadogRUM } = Config;
  if (useDatadogRUM) {
    RUMUtil.setUser(resData && resData.user);
  }

  dispatch({ 
    type: FETCH_USER_SUCCESS, 
    payload: resData 
  });
  return resData;
};

export const getMpssoSystemName = async accountId => {
  try {
    const res = await axios.get(`${userHost}/public/account/${accountId}`, {
      headers,
      withCredentials: false
    });
    return res.data.data.name;
  } catch (err) {
    return;
  }
};

export const updateIsMobile = screenWidth => ({
  type: UPDATE_IS_MOBILE,
  payload: getIsMobile(screenWidth)
});
