const env = process.env.ENV || 'dev';

module.exports = {
  env,
  api: {
    host: `${process.env.REACT_APP_EVENT_HOST}`,
    appHost: `${process.env.REACT_APP_HOST}`,
    userHost: `${process.env.REACT_APP_USER_HOST}`,
    mediaHost: `${process.env.REACT_APP_MEDIA_HOST}`,
    eventsHost: `${process.env.REACT_APP_COMPONENT_EVENT_HOST}`,
    viewerAnalyticsHost: `${process.env.REACT_APP_VIEWER_ANALYTICS_HOST}`,
    externalIpAddressService: `${process.env.REACT_APP_EXTERNAL_IP_SERVICE}`,
    mpssoHost: `${process.env.REACT_APP_MPSSO_HOST}`,
    esdHost: `${process.env.REACT_APP_ESD_HOST}`
  },
  vbi: {
    secretKey: `${process.env.REACT_APP_VBI_SECRET_KEY}`,
    accountKey: `${process.env.REACT_APP_VBI_ACCOUNT_KEY}`,
    proxy: `${process.env.REACT_APP_VBI_PROXY}`,
    streamName: `${process.env.REACT_APP_VBI_STREAM_NAME}`
  },
  accountId: '5952983052faff0006aaa385',
  pageSize: 100,
  maxPageSize: 9999,
  useDatadogRUM: process.env.REACT_APP_USE_DATADOG_RUM === 'true' ? true : false,
  datadogEnv: process.env.REACT_APP_DATADOG_ENV || 'dev',
  deployBranch: process.env.REACT_APP_DEPLOY_BRANCH || ''
};