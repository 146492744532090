import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { closeErrorModal } from '../../actions/errors';

const ErrorModal = props => (
  <Dialog
    open={props.errorModalOpen}
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal-description"
  >
    <DialogTitle>
      An Error Occurred
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        {props.errorMessage}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button
        onClick={props.dispatchCloseErrorModal}
        color="primary"
        autoFocus
      >
        Okay
      </Button>
    </DialogActions>
  </Dialog>
);

ErrorModal.propTypes = {
  errorModalOpen: PropTypes.bool,
  dispatchCloseErrorModal: PropTypes.func,
  errorMessage: PropTypes.string
};

ErrorModal.defaultProps = {
  errorModalOpen: false,
  errorMessage: 'There was an unexpected error.',
  dispatchCloseErrorModal: () => {}
};

const mapStateToProps = state => ({
  errorModalOpen: state.errors.modalOpen,
  errorMessage: state.errors.message
});

const mapDispatchToProps = dispatch => bindActionCreators({
  dispatchCloseErrorModal: closeErrorModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
