import { COMPONENT_EVENT_EVENT_CONFIG } from '../actions/config';

const initialState = {
  hideInArchiveConfig: []
};

const config = (state = initialState, action = {}) => {
  switch (action.type) {
    case COMPONENT_EVENT_EVENT_CONFIG: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export default config;
