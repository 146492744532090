import errorMessages from '../utils/errorMessages';
import {
  CLOSE_ERROR_MODAL,
  CLOSE_ERROR_SNACKBAR,
  FETCH_VIDEO_URLS_FAILURE,
  POST_COMPONENT_EVENT_FAILURE,
  POLL_COMPONENT_EVENTS_FAILURE,
  GET_CATCH_UP_EVENTS_FAILURE,
  FETCH_EVENT_FAILURE,
  FETCH_EVENT_THEME_FAILURE,
  ERROR_SNACKBAR,
  SERVICE_UNREACHABLE,
  FETCH_VIDEO_COOKIE_FAILURE
} from '../actions/errors';

const initialState = {
  modalOpen: false,
  message: '',
  errorType: '',
  errorSnackbar: {
    open: false,
    errorMessage: ""
  }
};

const generateErrorWithModal = action => ({
  // use the payload message or default error message for this type
  message: action.payload || errorMessages[action.type],
  modalOpen: true,
  errorType: action.type
});

/**
 * @description Global error handling for all failed http requests
 */
const errors = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATCH_UP_EVENTS_FAILURE:
    case FETCH_VIDEO_URLS_FAILURE:
    case FETCH_VIDEO_COOKIE_FAILURE:
    case POST_COMPONENT_EVENT_FAILURE:    
    case POLL_COMPONENT_EVENTS_FAILURE:
    case FETCH_EVENT_FAILURE:
    case FETCH_EVENT_THEME_FAILURE: {
      return { ...generateErrorWithModal(action) };
    }
    case ERROR_SNACKBAR: {
      const errorSnackbar = { ...state.errorSnackbar, ...action.payload };
      return { ...state, errorSnackbar }
    }
    case SERVICE_UNREACHABLE: {
      return {
        modalOpen: true,
        message: action.payload.errorMessage
      }
    }
    case CLOSE_ERROR_MODAL: {
      return {
        modalOpen: false,
        errorType: '',
        message: ''
      };
    }
    case CLOSE_ERROR_SNACKBAR: {
      const errorSnackbar = {
        open: false
      }
      return { ...state, errorSnackbar };
    }
    default: {
      return state;
    }
  }
};

export default errors;
