import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { EventConstants } from 'wcng-ui-common';

import CircularProgress from '@mui/material/CircularProgress';

import GenericRoute from './GenericRoute';
import EventRoute from './EventRoute';

import { fetchEvent } from '../actions/events';
import { updateIsMobile } from '../actions/user';

const EventGateway = lazy(() => import('./EventGateway'));
const RegistrationGateway = lazy(() => import('./lobby/RegistrationGateway'));
const Info = lazy(() => import('./info/Info'));
const NoMatch = lazy(() => import('./NoMatch'));
const Unauthorized = lazy(() => import('./Unauthorized'));
const { 
  EventStatusEnum: {
    SCHEDULED
  } = {} 
} = EventConstants;

function Main(props) {
  const {
    history,
    location: {
      search
    }
  } = props;

  const {
    event: {
      id,
      status
    },
    theme = {}
  } = useSelector(state => state.event);

  const dispatch = useDispatch();
  const pollEventInterval = useRef(null);

  // remove url hash
  useEffect(() => {
    if (window.location.hash) {
      history.replace(window.location.hash.replace('#', ''));
    }
  });

  // pdf worker config
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  }, []);

  useEffect(() => {
    const pollEvent = () => {
      dispatch(fetchEvent(id));
    }

    const clearPolling = () => {
      if (pollEventInterval) {
        clearInterval(pollEventInterval.current);
        pollEventInterval.current = null;
      }
    }

    if (id && status) {
      if (status === SCHEDULED) {
        if (!pollEventInterval.current) {
          pollEvent(id);
          pollEventInterval.current = setInterval(pollEvent, 60000);
        }
      } else {
        clearPolling();
      }
    } 
 
  return () => clearPolling();

  }, [id, status, dispatch]);

  useEffect(() => {
    dispatch(updateIsMobile(window.innerWidth));
  }, [dispatch])

  return (
    <ThemeProvider theme={theme}>
      <StyledMain>
        <Suspense
          fallback={
            <LoaderWrapper>
              <CircularProgress
                size={50}
                color="inherit"
              />
            </LoaderWrapper>
          }
        >
          <RouteWrapper>
            <Switch>
              <EventRoute exact path='/event/:eventId/:eventType/registration' component={RegistrationGateway} />
              <EventRoute exact path='/event/:eventId/:eventType/presentation' component={EventGateway} />
              <EventRoute exact path='/event/:eventId/registration' component={RegistrationGateway} />
              <EventRoute exact path='/event/:eventId/presentation' component={EventGateway} />
              <Redirect exact from="/event/:eventId" to={'/event/:eventId/presentation' + search} />
              <Redirect exact from="/event/:eventId/:eventType" to={'/event/:eventId/:eventType/presentation' + search} />
              <GenericRoute exact path='/unauthorized' component={Unauthorized} />
              <GenericRoute exact path='/info' component={Info} />
              <GenericRoute component={NoMatch} />
            </Switch>
          </RouteWrapper>
        </Suspense>
      </StyledMain>
    </ThemeProvider>
  );
}

Main.defaultProps = {
  history: {}
}

Main.propTypes = {
  history: PropTypes.object
}

const StyledMain = styled.div`
  height: 100%;
  display: flex;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  height: 100%;
`;

export default withRouter(Main);
