/**
 * Collection of logically named breakpoints
 * based on screen width.
 *
 * Sources for screen width sizes:
 * https://material.io/tools/devices/
 * https://kapeli.com/cheat_sheets/iOS_Design.docset/Contents/Resources/Documents/index
 *
 */
export const breakpoint = {
  /**
   * Targets smaller mobile phones such as iPhone 5, iPhone 5S, iPhone 5C, iPhone SE.
   * Most of the Samsung Galaxies have a logical width of 360px so would fall
   * somewhere in between `mobileSm` and `mobileLg`.
   */
  mobileSm: 320,
  /**
   * Targets the larger sized mobile phones such as
   * iPhone 6, iPhone 6S, iPhone 7, iPhone 8, iPhone X, iPhone XS, iPhone XS Max, iPhone XR.
   */
  mobileLg: 375,
  /**
   * Targets standard sized tablets such as
   * iPad Mini, iPad Mini Retina, iPad (1st and 2nd Generation), and iPad 9.7-inch Retina.
   * UPDATE: This was changed from 768 to 812 to include the latest phones with higher
   *         resolutions, like iPhone X+, in landscape mode.  We still want to render
   *         the mobile layout in landscape mode on phones.  Tablet landscape mode
   *         can support the desktop layout.
   */
  tablet: 812,
  /**
   * Targets standard sized laptops and smaller monitors.
   */
  laptop: 1024,
  /**
   * Targets laptops with higher resolutions such as MacBook Air 13+,
   * MacBook Pro 15+ and Surface Pro 3+.
   */
  laptopLg: 1440,
  /**
   * Targets desktop screens.
   */
  desktop: 2560
};

/**
 * Pre-defined media queries based on the pre-defined breakpoints.
 * Used in the ThemeProvider (styled-components).
 */
export const device = {
  mobileSm: `(min-width: ${breakpoint.mobileSm}px)`,
  mobileMd: `(min-width: ${breakpoint.mobileMd}px)`,
  mobileLg: `(min-width: ${breakpoint.mobileLg}px)`,
  tablet: `(min-width: ${breakpoint.tablet}px)`,
  tablet2: `(max-width: ${breakpoint.tablet}px)`,
  laptop: `(min-width: ${breakpoint.laptop}px)`,
  laptopLg: `(min-width: ${breakpoint.laptopLg}px)`,
  desktop: `(min-width: ${breakpoint.desktop}px)`
};
