import { combineReducers } from 'redux';
import event from './event';
import controlRoom from './controlRoom';
import user from './user';
import preview from './preview';
import video from './video';
import errors from './errors';
import debug from './debug';
import config from './config';

export default combineReducers({
  event,
  controlRoom,
  user,
  preview,
  video,
  errors,
  debug,
  config
});
