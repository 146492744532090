export const GET_CATCH_UP_EVENTS = 'GET_CATCH_UP_EVENTS';
export const GET_CATCH_UP_EVENTS_SUCCESS = 'GET_CATCH_UP_EVENTS_SUCCESS';

export const START_POLLING_COMPONENT_EVENTS = 'START_POLLING_COMPONENT_EVENTS';
export const STOP_POLLING_COMPONENT_EVENTS = 'STOP_POLLING_COMPONENT_EVENTS';
export const POLL_COMPONENT_EVENTS_SUCCESS = 'POLL_COMPONENT_EVENTS_SUCCESS';

export const getCatchUpEvents = (eventId, accountId, previewToken, startTime) => ({
  type: GET_CATCH_UP_EVENTS,
  payload: {
    eventId,
    accountId,
    previewToken,
    startTime
  }
});

export const startPollingComponentEvents = (eventId, accountId, previewToken) => ({
  type: START_POLLING_COMPONENT_EVENTS,
  payload: {
    eventId,
    accountId,
    previewToken
  }
});

export const stopPollingComponentEvents = () => ({
  type: STOP_POLLING_COMPONENT_EVENTS
});
