export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL';
export const CLOSE_ERROR_SNACKBAR = 'CLOSE_ERROR_SNACKBAR';
export const POST_COMPONENT_EVENT_FAILURE = 'POST_COMPONENT_EVENT_FAILURE';
export const GET_CATCH_UP_EVENTS_FAILURE = 'GET_CATCH_UP_EVENTS_FAILURE';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';
export const FETCH_EVENT_THEME_FAILURE = 'FETCH_EVENT_THEME_FAILURE';
export const POLL_COMPONENT_EVENTS_FAILURE = 'POLL_COMPONENT_EVENTS_FAILURE';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_VIDEO_URLS_FAILURE = 'FETCH_VIDEO_URLS_FAILURE';
export const ERROR_SNACKBAR = 'ERROR_SNACKBAR';
export const SERVICE_UNREACHABLE = 'SERVICE_UNREACHABLE';
export const POST_SENTIMENT_FAILURE = 'POST_SENTIMENT_FAILURE';
export const FETCH_VIDEO_COOKIE_FAILURE = 'FETCH_VIDEO_COOKIE_FAILURE';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';

export const closeErrorModal = () => ({
  type: CLOSE_ERROR_MODAL
});

export const closeErrorSnackbar = () => ({
  type: CLOSE_ERROR_SNACKBAR
});

export const errorSnackbar = data => ({
  type: ERROR_SNACKBAR,
  payload: data
});
