import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Icon from '@mui/material/Icon';

import { closeErrorSnackbar } from '../../actions/errors';

export class ErrorSnackbar extends Component {
  static defaultProps = {
    errorSnackbar: {
      open: false,
      errorMessage: 'There was an unexpected error',
    },
    dispatchErrorSnackbar: () => {},
    dispatchCloseErrorSnackbar: () => {}
  };

  static propTypes = {
    errorSnackbar: PropTypes.object,
    dispatchErrorSnackbar: PropTypes.func
  };

  handleClose = () => {
    this.props.dispatchCloseErrorSnackbar();
  };

  render() {
    return (
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.props.errorSnackbar.open}
        onClose={this.handleClose}
      >
        <SnackbarContent>
          <ErrorIconWrapper>
            <ErrorIcon>error_outline</ErrorIcon>
          </ErrorIconWrapper>

          <SnackbarMessage>{this.props.errorSnackbar.errorMessage}</SnackbarMessage>

          <CloseIcon onClick={this.handleClose}>close</CloseIcon>
        </SnackbarContent>
      </StyledSnackbar>
    );
  }
}

const mapStateToProps = state => ({
  errorSnackbar: state.errors.errorSnackbar
});

const mapDispatchToProps = dispatch => bindActionCreators({
  dispatchCloseErrorSnackbar: closeErrorSnackbar
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorSnackbar);

const StyledSnackbar = styled(Snackbar)`
  && {
    z-index: 1501;
    top: 10px;
  }
`;

const SnackbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.22) 5px 7px 9px 0px;
`;

const SnackbarMessage = styled.div`
  padding: .75rem;
  font-size: .9rem;
`;

const CloseIcon = styled(Icon)`
  margin-right: .75rem;
  cursor: pointer;
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  background: #D0021B;
`;

const ErrorIcon = styled(Icon)`
  color: #fff;
`;
