import axios from 'axios';
import qs from 'qs';
import Config from '../configs/config';

const { userHost } = Config.api;
const headers = { 'Content-Type': 'application/json' };

export const PREVIEW_TOKEN_SUCCESS = 'PREVIEW_TOKEN_SUCCESS';
export const CLEAR_PREVIEW_TOKEN = 'CLEAR_PREVIEW_TOKEN';

export const checkPreviewToken = (eventId, token) => async dispatch => {
  let res;
  const params = token && qs.stringify({ token });

  try {
    res = await axios.get(`${userHost}/user/event/${eventId}/me?${params}`, {
      headers,
      withCredentials: true
    });
  } catch (e) {
    dispatch({
      type: CLEAR_PREVIEW_TOKEN
    });
    throw e;
  }
  // PREVIEW_TOKEN_SUCCESS triggers a state update in both:
  // (1) reducers/preview.js
  // (2) reducers/user.js
  // because we get both a previewToken and user data:
  dispatch({
    type: PREVIEW_TOKEN_SUCCESS,
    payload: {
      previewToken: token,
      user: res.data.data.user,
      account: res.data.data.account
    }
  });
  return res;
};

export const clearPreviewToken = () => ({
  type: CLEAR_PREVIEW_TOKEN
});
