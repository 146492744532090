/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { RUMUtil } from 'wcng-ui-common';

import { LanguageTranslators } from './constants';

export const initI18n = () => {
  // set up language translations
  const resources = Object.entries(LanguageTranslators)
    .reduce((res, [key, { translation }]) => ({
      ...res,
      [key]: {
        translation
      }
    }), {});

  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      fallbackLng: 'en-US',
      interpolation: {
        escapeValue: false
      }
    });

  i18next.on('languageChanged', (lang) => {
    if (!resources[lang]) {
      RUMUtil.addAction(`registration_language_not_supported[${lang}]`);
      i18next.changeLanguage('en-US');
    } else {
      RUMUtil.addAction(`registration_select_language[${lang}]`);
    }
  });

  // LanguageDetector first run
  i18next.changeLanguage();
};
