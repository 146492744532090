import axios from 'axios';
import last from 'lodash/last';
import get from 'lodash/get';
import qs from 'qs';
import { HTTPUtils } from 'wcng-ui-common';
import { FETCH_VIDEO_URLS_FAILURE, FETCH_VIDEO_COOKIE_FAILURE } from './errors';
import config from '../configs/config';

import withCacheManager, { CacheEnum } from '../utils/cacheManager';

export const FETCH_VIDEO_URLS_SUCCESS = 'FETCH_VIDEO_URLS_SUCCESS';
export const UPDATE_VIDEO_PLAYING = 'UPDATE_VIDEO_PLAYING';
export const INITIATE_VIDEO_PLAYER = 'INITIATE_VIDEO_PLAYER';
export const DESTROY_VIDEO_PLAYER = 'DESTROY_VIDEO_PLAYER';
export const SET_SEEK_TO = 'SET_SEEK_TO';

export const fetchIPAddress = (accountId, eventId) => async () => {
  
  const externalIpAddressServiceURL = config.api.externalIpAddressService;
  const mediaHostAudience = config.api.mediaHost;
  
  // First, hit the external ip address service to get my external IP address
  let externalIpAddress = '';
  try {
    let externalIpResponse = await HTTPUtils.axiosNoRetry.get(externalIpAddressServiceURL, {
      withCredentials: false
    });
    console.log('External IP response: ' + externalIpResponse.data.client_ip);
    externalIpAddress = externalIpResponse.data.client_ip;
  } 
  catch (err) {
    // if we have a problem getting a response, It's not a deal-breaker. Log it and move along...
    console.log('Problem with checkip: ' + err);
  }
  
  // Next, hit the account config service to obtain internal IP address service URLs
  // These URLs will be cycled through to check for an internal IP adress.
  // Once any one of the IP address service URLs returns a successful response, we can move on.
  let internalIpAddress = '';
  try {
    let accountConfig = await HTTPUtils.axiosNoRetry.get(`${mediaHostAudience}/account/${accountId}/event/${eventId}/config`, {
      withCredentials: true
    });
    let serviceUrls = accountConfig.data.data.smartPathInternalIpAddressURLs; 
    console.log('Internal IP address URLs: ' + serviceUrls);

    if (serviceUrls) {
      for (const url of serviceUrls) {
        try {
          // Internal IP address services should return the ip address in a data.string payload
          // Source code I looked at for the response format is here:
          // https://github.com/mediaplatform/mp-smartedge/blob/master/smartedge-ui/src/main/java/com/mediaplatform/smartedge/web/controller/APIWhatIsMyIPController.java
          let res = await HTTPUtils.axiosNoRetry.get(url, { 
            responseType: 'text', 
            timeout: 500,
            withCredentials: false
          });
          console.log(`Internal IP response: ${url} | ${JSON.stringify(res)}`); 
          // check for a basic IPv4 IP address
          let regex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
          let responsePayload = res.data;
          
          //need to remove single quotes if there are any - WN-2715
          responsePayload = responsePayload.replace(/'/g, "");
          
          if (responsePayload && regex.test(responsePayload))
          {
            internalIpAddress = responsePayload;
            console.log('Internal IP Address winner: ' + internalIpAddress);
            break;
          }
          else {
            console.warn('Internal IP address rejected from: ' + url);
            console.warn('Internal IP address payload: ' + res.data);
          }
        } catch (error) {
          // if we have a problem getting a response, It's not a deal-breaker. Log it and move along...
          console.warn('Could not reach internal ip service: ' + error)
        }
      }
    }
  } 
  catch (error) {
    // if we have a problem getting a response, It's not a deal-breaker. Log it and move along...
    console.log('Problem with getting internal IP address: ' + error);
  }

  const url = window.location.href;
  const queryString = last(url.split('?'));

  // override internalIpAddres to the one provided through the URL, if one exists
  internalIpAddress = get(qs.parse(queryString), 'internalIPOverride', internalIpAddress);

  return {internalIpAddress: internalIpAddress, externalIpAddress:externalIpAddress};
};

export const fetchVideoUrl = (smartPathUrl, accountId, eventId, externalIpAddress, internalIpAddress) => async (dispatch) => {
  const SMART_PATH_URL = `${smartPathUrl}/account/${accountId}/event/${eventId}/smartPath/url`;

  let res;
  let smartPathUrls;

  try {
    res = await axios.get(SMART_PATH_URL, {
      headers: { 'X-mp-ipaddress-external': externalIpAddress , 'X-mp-ipaddress-internal': internalIpAddress },
      withCredentials: true
    });

    smartPathUrls = res.data.data.map(smartPath => ({
      src: smartPath.url,
      type: 'application/x-mpegURL',
      peering: smartPath.peering
    }));
    dispatch(fetchVideoUrlSuccess(smartPathUrls));
  } catch (err) {
    console.error(err);
    console.log(err.response);
    dispatch({
      type: FETCH_VIDEO_URLS_FAILURE,
      payload: "There are no streams available for your device/browser" 
    });
  }

  return smartPathUrls;
};

export const fetchMicrosoftTenantId = (accountId, eventId) => async () => {
  const mediaHostAudience = config.api.mediaHost;

  const accountConfig = await HTTPUtils.axiosNoRetry.get(`${mediaHostAudience}/account/${accountId}/event/${eventId}/config`, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    timeout: 5000
  });

  const {
    data: {
      data: {
        videoDistributions: {
          MICROSOFT_ECDN: {
            token = ''
          } = {}
        } = {}
      } = {}
    } = {}
  } = accountConfig;

  return token;
};

export const fetchHiveUrl = (accountId, eventId) => async (dispatch) => {
  const HIVE_URL = `${config.api.mediaHost}/account/${accountId}/event/${eventId}/videoDistribution/hive`;  
  let hiveUrl;

  await axios.get(HIVE_URL, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
  })
    .then((res) => {
      hiveUrl = [{
        src: res.data.data.videoStreamData,
        type: 'application/x-mpegURL'
      }];
      dispatch(fetchVideoUrlSuccess(hiveUrl));
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: FETCH_VIDEO_URLS_FAILURE });
    });

  return hiveUrl;
};

export const fetchVODVideoUrl = (accountId, eventId) => async dispatch => {
  // received video url will expire after few minutes
  const VOD_ENDPOINT = `${config.api.host}/account/${accountId}/event/${eventId}/generateRecordingLink`;
  let url;

  await axios(VOD_ENDPOINT, {
      method: 'POST'
    })
    .then(res => {
      if (!res) {
        dispatch({ type: FETCH_VIDEO_URLS_FAILURE });
        return;
      }
      url = [res.data.data.url];
      dispatch(fetchVideoUrlSuccess(url));
      
    })
    .catch(err => {
      dispatch({ type: FETCH_VIDEO_URLS_FAILURE });
    });
    return url;
};

export const getPrerecordedData = (accountId, eventId) => async () => {
  try {
    const {
      data: {
        data = []
      } = {}
    } = await axios.get(`${config.api.mediaHost}/account/${accountId}/event/${eventId}/stream`);
    return data[0];
  } catch (err) {
    console.error(err);
  }
};

export const fetchArchivedComponentEvents = (accountId, eventId) => async () => {
  const { eventsHost } = config.api;

  try {
    const {
      data: {
        data = []
      } = {}
    } = await axios.get(`${eventsHost}/account/${accountId}/event/${eventId}/componentEvent/archived`);
    return data;
  } catch (err) {
    console.error(err); 
    return [];
  }
};

export const fetchPrerecordedVideoUrl = (accountId, eventId, viewingUrl) => async (dispatch) => {
  let url;
  try {
    const res = await axios.post(`${config.api.host}/account/${accountId}/event/${eventId}/generateViewingLink?filename=${viewingUrl}`);
    const { data: { data: { url:prerecordedUrl } = {} } = {} } = res;
    url = [{
      src: prerecordedUrl,
      type: 'video/mp4'
    }];
    dispatch(fetchVideoUrlSuccess(url));
  } catch (err) {
    console.error(err); 
    dispatch({
      type: FETCH_VIDEO_URLS_FAILURE,
      payload: "There are no streams available for your device/browser" 
    });
  }

  return url;
};

export const videoStreamAuth = (accountId, eventId) => async (dispatch) => {
  try {
      const authResp = await axios.get(`${config.api.host}/account/${accountId}/event/${eventId}/signedPolicyParameters`, {
        withCredentials: true
      });
      return authResp.data.data.parameters;
  } 
  catch (err) {
      console.error(err);
      dispatch({ type: FETCH_VIDEO_COOKIE_FAILURE });
      return err;
  }
};

export const setCookies = (accountId, eventId, externalIpAddress, internalIpAddress) => async (dispatch) => {
  const audienceHost = config.api.host;

  try {
    await axios.post(`${audienceHost}/account/${accountId}/event/${eventId}/authorizeStream`, null, {
      headers: { 'X-mp-ipaddress-external': externalIpAddress, 'X-mp-ipaddress-internal': internalIpAddress },
      withCredentials: true
    });

    return true;
  } 
  catch (err) {
      console.error(err);
      dispatch({ type: FETCH_VIDEO_COOKIE_FAILURE });
      return err;
  }
};

const fetchVideoUrlSuccess = urls => ({
   type: FETCH_VIDEO_URLS_SUCCESS, 
   payload: urls 
})

export const updateVideoPlaying = isPlaying => ({
  type: UPDATE_VIDEO_PLAYING,
  payload: isPlaying
});

export const initiateVideoPlayer = (
    eventId, 
    vbiAccountId, 
    userId, 
    videoId, 
    videoOptions, 
    smartPathUrls, 
    type, 
    hasPreviewToken, 
    authParams, 
    presentationType = '',
    seekTo
  ) => ({
  type: INITIATE_VIDEO_PLAYER,
  payload: {
    eventId, 
    vbiAccountId,
    userId,
    videoId,
    videoOptions,
    smartPathUrls,
    type,
    hasPreviewToken,
    authParams,
    presentationType,
    seekTo
  }
});

export const destroyVideoPlayer = (elemId) => ({
  type: DESTROY_VIDEO_PLAYER,
  payload: {
    elemId
  }
});

export const setSeekTo = (seconds) => ({
  type: SET_SEEK_TO,
  payload: seconds
})

/*
  Fetch methods decorated with caching behavior.
*/

export const fetchIPAddressWithCache = (accountId, eventId) => async (dispatch) => {
  const key = `${CacheEnum.IP_ADDRESS}-${eventId}`;
  return withCacheManager(
    fetchIPAddress, 
    key, 
    dispatch, 
    null, 
    accountId,
    eventId
  )
};

export const fetchVideoUrlWithCache = (smartPathUrl, accountId, eventId, externalIpAddress, internalIpAddress) => async (dispatch) => {
  const key = `${CacheEnum.VIDEO_URLS}-${eventId}`;
  return withCacheManager(
    fetchVideoUrl, 
    key, 
    dispatch, 
    fetchVideoUrlSuccess, 
    smartPathUrl, 
    accountId, 
    eventId, 
    externalIpAddress, 
    internalIpAddress
  )
};

export const fetchMicrosoftTenantIdWithCache = (accountId, eventId) => async (dispatch) => {
  const key = `${CacheEnum.MICROSOFT_ECDN_TENANT_ID}-${eventId}`;
  return withCacheManager(
    fetchMicrosoftTenantId,
    key,
    dispatch,
    null,
    accountId,
    eventId
  );
};

export const fetchHiveUrlWithCache = (accountId, eventId, eventName) => async (dispatch) => {
  const key = `${CacheEnum.HIVE_URL}-${eventId}`;
  return withCacheManager(
    fetchHiveUrl, 
    key, 
    dispatch, 
    fetchVideoUrlSuccess, 
    accountId, 
    eventId, 
    eventName
  )
};

export const videoStreamAuthWithCache = (accountId, eventId) => async (dispatch) => {
  const key = `${CacheEnum.VIDEO_STREAM_AUTH}-${eventId}`;
  return withCacheManager(
    videoStreamAuth, 
    key, 
    dispatch, 
    null, 
    accountId,
    eventId
  )
};

export const setCookiesWithCache = (accountId, eventId, externalIpAddress, internalIpAddress) => async (dispatch) => {
  const key = `${CacheEnum.SET_COOKIES}-${eventId}`;
  return withCacheManager(
    setCookies, 
    key, 
    dispatch, 
    null, 
    accountId, 
    eventId,
    externalIpAddress, 
    internalIpAddress
  )
};