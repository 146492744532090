import { 
  EventPumpAudience, 
  EventConstants,
  componentEvents 
} from 'wcng-ui-common';
import config from '../configs/config';

export const POST_COMPONENT_EVENT_SUCCESS = 'POST_COMPONENT_EVENT_SUCCESS';
export const GET_CATCH_UP_EVENTS_SUCCESS = 'GET_CATCH_UP_EVENTS_SUCCESS';

const { instance } = EventPumpAudience;
const { AudienceAppType } = EventConstants;

export const generateGuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

export const postComponentEvent = async (metadata, data, dispatch) => {
  try {
    const request = await instance.post(metadata, data, config.api.eventsHost);

    // after the request is successful, pass the event through the event pump:
    const reducedEvents = instance.reduceEvents([{ payload: data }]);
    dispatch({ type: POST_COMPONENT_EVENT_SUCCESS, payload: reducedEvents });

    return request;
  } catch (e) {
    console.error(e);
  }

  return true;
};

export const askQuestion = (
  metadata, 
  user, 
  account, 
  questionText,
  liveAudience = false
  ) => (dispatch) => {
  const payload = {
    type: componentEvents.CREATE_QUESTION,
    audienceAppType: liveAudience ? AudienceAppType.ME : AudienceAppType.STANDARD,
    data: {
      question: {
        id: `question_${generateGuidv4()}`,
        text: questionText,
        read: false,
        time: new Date(),
        user: {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          companyName: account.name
        },
        folder: {},
        answer: {}
      }
    }
  };

  postComponentEvent(metadata, payload, dispatch);
};

export const answerPoll = (metadata, data, account, liveAudience = false) => (dispatch) => {
  const payload = {
    type: componentEvents.SUBMIT_POLL,
    audienceAppType: liveAudience ? AudienceAppType.ME : AudienceAppType.STANDARD,
    pollId: metadata.pollId,
    data: {
      ...data,
      companyName: account.name,
      time: new Date()
    }
  };

  postComponentEvent(metadata, payload, dispatch);
};

export const answerSurvey = (metadata, data, account, liveAudience = false) => (dispatch) => {
  const payload = {
    type: componentEvents.SUBMIT_SURVEY,
    audienceAppType: liveAudience ? AudienceAppType.ME : AudienceAppType.STANDARD,
    surveyId: metadata.surveyId,
    data: {
      ...data,
      companyName: account.name,
      time: new Date()
    }
  };

  postComponentEvent(metadata, payload, dispatch);
};
