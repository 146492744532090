import {
  PREVIEW_TOKEN_SUCCESS,
  CLEAR_PREVIEW_TOKEN
} from '../actions/preview';

const initialState = {
  previewToken: false,
  user: {},
  account: {}
};

const preview = (state = initialState, action) => {
  switch (action.type) {
    case PREVIEW_TOKEN_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case CLEAR_PREVIEW_TOKEN: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}

export default preview;
