/**
 * @todo remove duplicate constants across codebases.
 */
import translationEN from '../locales/en/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationCA from '../locales/ca/translation.json';
import translationES from '../locales/es/translation.json';
import translationSP from '../locales/sp/translation.json';
import translationDE from '../locales/de/translation.json';
import translationZH from '../locales/zh/translation.json';
import translationHI from '../locales/hi/translation.json';
import translationIT from '../locales/it/translation.json';
import translationJA from '../locales/ja/translation.json';
import translationKO from '../locales/ko/translation.json';
import translationPT from '../locales/pt/translation.json';
import translationUK from '../locales/uk/translation.json';

export const ComponentEventConstants = {
  CREATE_PRODUCER_LAYOUT: 'createProducerLayout',
  DELETE_PRODUCER_LAYOUT: 'deleteProducerLayout',
  EDIT_PRODUCER_LAYOUT: 'editProducerLayout',
  CREATE_AUDIENCE_LAYOUT: 'createAudienceLayout',
  DELETE_AUDIENCE_LAYOUT: 'deleteAudienceLayout',
  EDIT_AUDIENCE_LAYOUT: 'editAudienceLayout',
  CREATE_PRODUCER_COMPONENT: 'createProducerComponent',
  EDIT_PRODUCER_COMPONENT: 'editProducerComponent',
  DELETE_PRODUCER_COMPONENT: 'deleteProducerComponent',
  CREATE_AUDIENCE_COMPONENT: 'createAudienceComponent',
  EDIT_AUDIENCE_COMPONENT: 'editAudienceComponent',
  DELETE_AUDIENCE_COMPONENT: 'deleteAudienceComponent',
  CREATE_QUESTION: 'createQuestion',
  EDIT_QUESTION: 'editQuestion',
  DELETE_QUESTION: 'deleteQuestion',
  CREATE_FOLDER: 'createFolder'
};

export const SurveyQuestionTypes = Object.freeze({
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  USER: 'user'
});

export const ValidationPropertyEnum = Object.freeze({
  registration: {
    NOT_NULL: 'notNull',
    INVALID_FORMAT: 'invalidFormat'
  }
});

export const HeaderEnum = Object.freeze({
  X_MP_ERROR_MESSAGE: 'x-mp-error-message'
});

export const AppConstants = {
  // EVENT TYPES
  EVENT_TYPE: {
    CREATE: 'create',
    UPDATE: 'update',
    HIDE: 'hide',
    DELETE: 'delete',
    START: 'start',
    STOP: 'stop'
  },
  SECONDARY_EVENT_TYPE: { // mainly for inside components
    ADD_FOLDER: 'add_folder',
    NEW_QUESTION: 'new_question',
    UPDATE_QUESTION_READ: 'update_question_read',
    UPDATE_QUESTION_ADD_FOLDER: 'update_question_add_folder',
    UPDATE_QUESTION_REMOVE_FOLDER: 'update_question_remove_folder',
    UPDATE_QUESTION_ADD_ANSWER: 'update_question_add_answer',
    UPDATE_QUESTION_RETRACT_ANSWER: 'update_question_retract_answer',
    UPDATE_SELECTED_LAYOUT: 'update_selected_layout'
  },
  ROLE: {
    USER_ROLE: 'user'
  },
  LAYOUT: {
    BACKSTAGEDEFAULTID: 'BACKSTAGE_DEFAULT_LAYOUT',
    VIEWERDEFAULTID: 'VIEWER_DEFAULT_LAYOUT',
    ALL: 'all'
  }
};

export const ComponentsConstants = {
  ids: {
    defaultVideoId: 'VIDEOAUDIO_DEFAULT_COMPONENT_ID'
  },
  type: {
    component: 'COMPONENT',
    BACKSTAGELAYOUT: 'BACKSTAGELAYOUT',
    VIEWERLAYOUT: 'VIEWERLAYOUT',
    event: 'EVENT'
  },
  DESTINATIONS: {
    VIEWER: 'viewer',
    PRODUCER: 'producer'
  },
  name: {
    VIEWER: 'VIEWER',
    PRODUCER: 'PRODUCER',
    WORKSPACE: 'WORKSPACE',
    CHAT: 'CHAT',
    VIDEOAUDIO: 'VIDEOAUDIO',
    LAYOUT: 'LAYOUT',
    DEMO: 'DEMO',
    SLIDESHOW: 'SLIDESHOW',
    POLLS: 'POLLS',
    SURVEYS: 'SURVEYS',
    QA: 'QA',
    WHITEBOARD: 'WHITEBOARD',
    PICTURE: 'PICTURE'
  },
  displayName: {
    VIEWER: 'Viewer',
    PRODUCER: 'Producer',
    WORKSPACE: 'Workspace',
    CHAT: 'Chat',
    VIDEOAUDIO: 'VIDEO/AUDIO',
    LAYOUT: 'Layout',
    DEMO: 'Demo',
    SLIDESHOW: 'Slideshow',
    QA: 'Q & A',
    POLLS: 'Polls',
    SURVEYS: 'Surveys',
    WHITEBOARD: 'Whiteboard',
    PICTURE: 'Picture'
  }
};

export const Messages = {
  LOCKED_LAYOUT: 'This layout is currently locked and cannot be edited',
  COPY_LINK: 'Event URL has been copied to the clipboard',
  COPY_RTMP: 'Encoder URL has been copied to the clipboard',
  ANSWER_SENT: 'Your answer has been sent',
  MOVED_TRASH_ONE: '1 item have been moved to the trash',
  MOVED_TRASH_MANY: 'items have been moved to the trash',
  HIDE_VIDEO_COMPONENT: 'Video component hidden from active layout. Audience will only hear audio.',
  NO_STREAM_START_EVENT: 'Setup video encoder before starting',
  REGISTER_SUCCESS: 'You are now registered (or "signed in"). Please hang tight for the event to start.'
};

export const userColors = ['blue-text text-darken-2', 'blue-text', 'orange-text text-darken-4',
  'purple-text text-darken-2', 'purple-text text-accent-3', 'red-text text-darken-1',
  'red-text text-lighten-3', 'deep-purple-text text-lighten-3',
  'deep-purple-text text-darken-3', 'green-text', 'green-text text-darken-4',
  'light-green-text text-darken-4', 'light-green-text', 'lime-text text-darken-3',
  'deep-purple-text text-lighten-1'
];

export const DefaultComponentProps = {
  coordinates: { left: 0, top: 0 },
  dimensions: { width: 0, height: 0, zIndex: 0 }
};

export const LanguageTranslators = {
  'zh-CN': {
    id: 'zh-CN',
    name: 'Chinese (simplified)',
    translation: translationZH
  },
  'en-US': {
    id: 'en-US',
    name: 'English',
    translation: translationEN
  },
  'fr-FR': {
    id: 'fr-FR',
    name: 'French',
    translation: translationFR
  },
  'fr-CA': {
    id: 'fr-CA',
    name: 'French (Canadian)',
    translation: translationCA
  },
  'de-DE': {
    id: 'de-DE',
    name: 'German',
    translation: translationDE
  },
  'hi-IN': {
    id: 'hi-IN',
    name: 'Hindi',
    translation: translationHI
  },
  'it-IT': {
    id: 'it-IT',
    name: 'Italian',
    translation: translationIT
  },
  'ja-JP': {
    id: 'ja-JP',
    name: 'Japanese',
    translation: translationJA
  },
  'ko-KR': {
    id: 'ko-KR',
    name: 'Korean',
    translation: translationKO
  },
  'pt-PT': {
    id: 'pt-PT',
    name: 'Portuguese',
    translation: translationPT
  },
  'es-SP': {
    id: 'es-SP',
    name: 'Spanish (Castilian)',
    translation: translationSP
  },
  'es-LA': {
    id: 'es-LA',
    name: 'Spanish (Latin American)',
    translation: translationES
  },
  'uk-UA': {
    id: 'uk-UA',
    name: 'Ukrainian',
    translation: translationUK
  }
};